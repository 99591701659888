import { createTheme } from "@mui/material/styles";

// Material UI theme documentation: https://mui.com/material-ui/customization/theming/

const theme = createTheme({
  palette: {
    primary: {
      main: "#003755",
    }
}});

export { theme };